/**
 CartDisplay Styles - css-in-js is preferred but sometimes we do need to utilize the power of media queries!
*/
.cart-display__summary {
  width: 100vw;
}
@media screen and (min-width: 900px) {
  .cart-display__summary {
    width: 50vw;
  }
}

.highlight {
  border-radius: 3px;
  animation: highlight 3000ms ease-out;
}
@keyframes highlight {
  0% {
    background-color: #fff7e2;
  }
  100% {
    background-color: transparent;
  }
}
